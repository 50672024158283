import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["player", "button"]

  toggle(event) {
    const button = event.currentTarget;
    const player = this.playerTarget;

    if (player.paused) {
      this.pauseAll(); // Stop any other playing audio
      player.play();
      button.innerHTML = "⏸ Pause";
    } else {
      player.pause();
      button.innerHTML = "▶ Play";
    }
  }

  pauseAll() {
    // Pause all audio players on the page
    document.querySelectorAll("audio").forEach((audio) => {
      audio.pause();
    });

    // Reset all buttons to "Play"
    document.querySelectorAll("[data-audio-target='button']").forEach((button) => {
      button.innerHTML = "▶ Play";
    });
  }
}
