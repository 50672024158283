import consumer from "./consumer";

document.addEventListener("turbo:load", function () {
  if (App && !App.user) {
    App.user = consumer.subscriptions.create(
      { channel: "UserChannel" },
      {
        connected: function () {
          // console.log("Connected to user channel");
          this.checkForPlayUpdate();
        },

        disconnected: function () {
          // console.log("Disconnected from user channel");
        },

        received: function (data) {
          console.log(data);
          switch (data["type"]) {
            case "remove_play":
              this.removePlay(data);
              break;
            case "reload":
              this.reload(data);
              break;
            case "redirection":
              this.redirection(data);
              break;
          }
        },

        checkForPlayUpdate() {
          // Retrieve the play ID from the DOM
          const container = document.getElementById("play-partial");
          if (!container) return;

          const playId = container.dataset.playId;

          fetch(`/plays/${playId}/partial`)
            .then((response) => response.text())
            .then((newHTML) => {
              // Compare the fetched HTML with the current inner HTML
              if (container.innerHTML.trim() !== newHTML.trim()) {
                console.log("Play partial has changed – updating DOM.");
                container.innerHTML = newHTML;
              }
            })
            .catch((error) =>
              console.error("Error fetching play partial:", error)
            );
        },

        removePlay: function (data) {
          let playStatus = document.getElementById("play-status");
          if (playStatus && playStatus.dataset["playId"] == data["play_id"]) {
            window.location.href = data["redirect"];
          }
        },

        updateAuthenticityTokens: function () {
          let token = document.getElementsByName("csrf-token")[0].content;
          if (token) {
            let elementsToUpdate =
              document.getElementsByName("authenticity_token");
            for (let index = 0; index < elementsToUpdate.length; index++) {
              elementsToUpdate[index].value = token;
            }
          }
        },

        reload: function (data) {
          if (data["page"]) {
            if (data["page"] == window.location.pathname) {
              window.location.href = window.location.href;
            }
          } else {
            window.location.href = window.location.href;
          }
        },

        redirection: function (data) {
          window.location.href = data["redirect"];
        },
      }
    );
  }
});
