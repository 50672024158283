import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link"];
  updateHref(event) {
    const isChecked = event.target.checked;
    var button = this.linkTarget;
    var url = new URL(button.href);

    if (isChecked) {
      url.searchParams.delete("use_minimum_price");
    } else {
      url.searchParams.set("use_minimum_price", "false");
    }
    // Update the href attribute of the link
    button.href = url.toString();
  }

  updateHrefPdfViewButton(event){
    const value = event.target.value;
    const button = this.linkTarget
    const url = new URL(button.href);

    url.searchParams.set("custom_price_per_visitor", value);
    button.href = url.toString();
  }
}
